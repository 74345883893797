import { List, Datagrid, TextField, DateField } from "react-admin";

import { Button } from '@mui/material';
import { useRecordContext } from 'react-admin';

const ConfirmButton = () => {
  const record = useRecordContext();
  const handleClick = () => {
      console.log(record.etag);
  };
  return <Button onClick={handleClick}>Confirm order</Button>
};

export const OrderedModels = (props) => (
  <List {...props} title="Orders storage">
    <Datagrid>
        {/* <TextField source="id" /> */}
        <TextField source="meta.filename" label="Filename" />
        {/* <TextField source="etag" label="Etag" /> */}
        <TextField source="meta.material" label="Material" />
        <TextField source="meta.quantity" label="Qty" />
        <TextField source="meta.orderPrice" label="Total price, $" />
        <TextField source="meta.modelDimensions" label="Dimensions" />
        <TextField source="meta.modelWeight" label="Unit weight" />
        <TextField source="email" />
        <DateField source="orderTime" />
        <ConfirmButton />
    </Datagrid>
  </List>
);

export const NotOrderedModels = (props) => (
  <List {...props} title="Orders storage">
    <Datagrid>
        {/* <TextField source="id" /> */}
        <TextField source="meta.filename" label="Filename" />
        {/* <TextField source="etag" label="Etag" /> */}
        <TextField source="meta.material" label="Material" />
        <TextField source="meta.quantity" label="Qty" />
        <TextField source="meta.orderPrice" label="Total price, $" />
        <TextField source="meta.modelDimensions" label="Dimensions" />
        <TextField source="meta.modelWeight" label="Unit weight" />
        <TextField source="email" />
    </Datagrid>
  </List>
);