// import React, { useEffect } from "react";
import { Admin, Resource} from "react-admin";
import dataProvider from './dataProvider.ts';
import { OrderedModels, NotOrderedModels } from "./completeOrders.tsx";

const authProvider = {
  login: ({ username, password }) => {
    const users = [
      { username: 'arcobo', password: 'arcobo2023' }
    ];

    const user = users.find(user => user.username === username && user.password === password);

    if (user) {
      localStorage.setItem('username', username);
      return Promise.resolve();
    }

    return Promise.reject();
  },
  logout: () => {
    localStorage.removeItem('username');
    return Promise.resolve();
  },
  checkError: () => Promise.resolve(),
  checkAuth: () => localStorage.getItem('username') ? Promise.resolve() : Promise.reject(),
  getPermissions: () => Promise.resolve(),
}

export const App = () => {
  // useEffect(() => {
  //   const fetchData = async () => {
  //     try {
  //       const response = await dataProvider.getList("download", {});
  //       console.log(response);
  //     } catch (error) {
  //       console.log(error);
  //     }
  //   };

  //   fetchData();
  // }, []);

  return (
    <Admin authProvider={authProvider} dataProvider={dataProvider}>   
      <Resource name="fetch_ordered_models" list={OrderedModels} options={{ label: 'Ordered models' }} />
      <Resource name="fetch_uploaded_not_ordered_models" list={NotOrderedModels} options={{ label: 'Not ordered modeles' }} />
    </Admin>
  );
};