// import jsonServerProvider from 'ra-data-json-server';

// export const dataProvider = jsonServerProvider(
//     import.meta.env.VITE_JSON_SERVER_URL
// );
// import fakeRestDataProvider from "ra-data-fakerest";
// import data from "./data.json";

// export const dataProvider = fakeRestDataProvider(data, true);


const dataProvider = {
    getList: async (resource, params) => {
        const { page, perPage } = params.pagination;
        const url = `/api/${resource}?skip=${perPage * (page - 1)}&limit=${perPage}`;

        const response = await fetch(url);

        if (!response.ok) {
        throw new Error(`Could not fetch ${url}`);
        }

        const result = await response.json();

        return {
        data: result.data,
        total: result.total,
        };
    },
};
  
export default dataProvider;